import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 616.000000 696.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,696.000000) scale(0.100000,-0.100000)">
					<path d="M2170 6180 c-84 -15 -304 -102 -330 -130 -3 -3 -21 -17 -40 -30 -65
-43 -197 -184 -240 -254 -69 -115 -143 -328 -127 -369 4 -11 25 -13 89 -9 89
4 108 15 108 59 0 38 40 144 83 218 59 102 191 229 292 281 254 131 557 105
781 -67 149 -114 236 -259 279 -461 l5 -28 92 0 91 0 -6 58 c-4 31 -20 94 -36
139 -78 219 -228 391 -436 500 -52 27 -94 42 -261 94 -44 13 -111 11 -117 -3
-3 -10 -9 -10 -26 0 -23 13 -129 14 -201 2z"/>
					<path d="M4170 4960 l0 -350 365 0 365 0 0 -633 c0 -349 3 -837 7 -1085 l6
-452 388 0 c213 0 390 4 393 8 3 5 1 312 -4 683 -5 371 -10 854 -12 1074 l-3
400 243 3 242 2 0 350 0 350 -995 0 -995 0 0 -350z"/>
					<path d="M849 5260 c-92 -23 -191 -118 -231 -220 -30 -77 -28 -160 10 -445 62
-458 67 -490 100 -586 72 -208 215 -342 417 -393 154 -38 166 -39 452 -30 429
12 683 9 783 -10 47 -9 92 -17 100 -19 9 -1 61 -24 115 -52 198 -99 265 -205
219 -342 -79 -233 -434 -368 -920 -349 -260 9 -478 53 -694 140 -147 59 -235
112 -321 193 -38 36 -71 64 -73 62 -2 -2 2 -44 9 -94 12 -82 22 -157 60 -465
20 -160 55 -413 60 -428 7 -19 2275 -18 2377 2 105 21 208 64 279 119 74 56
88 70 129 122 77 99 82 123 150 690 15 124 -3 257 -52 385 -20 53 -57 129 -68
140 -3 3 -22 30 -43 61 -90 135 -275 271 -445 329 -148 50 -366 86 -472 78
l-45 -3 -17 -90 c-21 -117 -43 -212 -58 -261 -21 -64 -74 -189 -82 -192 -5 -2
-8 -14 -8 -27 0 -13 -4 -26 -9 -29 -13 -8 -42 4 -35 15 3 5 -11 9 -30 9 -41 0
-66 22 -66 59 0 15 -8 33 -19 40 -15 11 -18 22 -14 52 l6 39 -57 0 -56 0 0 40
c0 22 5 40 10 40 6 0 10 18 10 40 0 38 -2 40 -30 40 -23 0 -30 4 -30 20 0 11
5 20 10 20 6 0 10 9 10 20 0 18 -7 20 -60 20 l-60 0 0 40 c0 33 -3 40 -20 40
-11 0 -20 7 -20 15 0 11 -9 15 -27 13 -16 -1 -37 3 -48 9 -11 6 -40 14 -64 18
-86 14 -181 79 -207 141 -24 56 -18 96 23 155 20 30 41 57 47 61 6 4 11 15 11
24 0 10 6 19 14 22 10 3 12 0 8 -12 -8 -20 -3 -20 30 1 l27 18 -29 7 c-21 5
-14 6 20 3 28 -2 45 -1 38 1 -9 4 -9 11 -1 29 17 38 4 55 -42 55 -39 0 -40 -1
-40 -35 0 -24 -5 -35 -15 -35 -8 0 -16 -6 -17 -12 -1 -7 -4 25 -7 72 l-6 85
-35 1 c-26 1 -28 1 -7 -3 23 -4 27 -9 27 -39 0 -31 -2 -34 -30 -34 l-30 0 0
120 c0 66 -3 120 -8 120 -4 0 -6 -18 -4 -40 4 -40 4 -40 -32 -40 -35 0 -36 1
-36 36 0 22 -6 41 -16 48 -14 11 -14 13 0 25 13 11 17 10 25 -10 9 -23 10 -23
10 -2 1 12 5 23 11 25 5 2 0 15 -13 30 l-23 26 -22 -25 c-12 -14 -25 -36 -28
-49 -9 -34 -24 -30 -24 5 l0 30 -34 -14 c-66 -28 -144 16 -167 95 -10 32 -10
46 0 68 7 15 13 29 12 32 -1 9 73 50 90 50 8 0 24 7 35 15 18 14 16 15 -21 11
-22 -3 -31 -3 -20 0 41 12 39 24 -6 24 -24 0 -51 5 -59 10 -11 7 0 10 38 10
28 0 52 5 52 10 0 6 -61 10 -162 10 -90 0 -233 2 -318 5 -85 2 -171 0 -191 -5z
m591 -80 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m740 -1240 c0 -11 -4 -20 -10 -20 -5 0 -10 9 -10 20 0 11 5 20 10 20 6 0 10
-9 10 -20z"/>
					<path d="M1565 5180 c-3 -5 -4 -10 -1 -10 2 0 11 -3 20 -6 11 -4 16 -1 16 10
0 18 -25 22 -35 6z"/>
					<path d="M1650 5070 c0 -13 5 -20 13 -17 6 2 12 10 12 17 0 7 -6 15 -12 18 -8
2 -13 -5 -13 -18z"/>
					<path d="M1640 4990 c-6 -12 -9 -24 -7 -27 3 -2 11 5 17 17 6 12 9 24 7 27 -3
2 -11 -5 -17 -17z"/>
					<path d="M2711 4944 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z" />
					<path d="M3057 4940 c3 -11 0 -20 -6 -20 -6 0 -11 -4 -11 -10 0 -14 23 -13 43
3 14 11 14 14 -7 29 -23 17 -24 17 -19 -2z"/>
					<path d="M3120 4901 c0 -5 -12 -12 -27 -14 -20 -3 -16 -4 14 -6 34 -1 41 2 37
14 -7 16 -24 21 -24 6z"/>
					<path d="M3250 4890 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
					<path d="M2682 4869 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
					<path d="M4027 4873 c-4 -3 -7 -15 -7 -25 0 -10 -4 -18 -10 -18 -5 0 -10 -7
-10 -15 0 -13 -51 -15 -400 -15 -393 0 -400 0 -400 20 0 17 -7 20 -40 20 -33
0 -40 -3 -40 -20 0 -19 -7 -20 -200 -20 -153 0 -200 3 -200 13 -1 8 -4 7 -9
-3 -5 -8 -4 -42 1 -77 l11 -62 51 -10 c28 -5 85 -14 126 -21 86 -13 146 -25
245 -52 38 -10 84 -18 102 -18 19 0 36 -6 41 -16 9 -17 113 -64 140 -64 9 0
13 -3 9 -6 -3 -4 8 -14 26 -23 174 -92 340 -231 444 -370 26 -36 53 -66 59
-68 7 -2 14 15 17 44 3 26 15 133 26 237 12 103 28 240 36 305 23 177 15 298
-18 264z m15 -113 c-2 -22 -6 -40 -8 -40 -2 0 -4 18 -4 40 0 22 3 40 8 40 4 0
6 -18 4 -40z m-35 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-1210 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
					<path d="M2000 4620 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
					<path d="M3258 4553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
					<path d="M2203 4083 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z" />
					<path d="M1003 1604 c-10 -4 -13 -73 -13 -297 l0 -292 24 -22 c23 -22 31 -23
209 -23 117 0 188 4 192 10 4 6 7 37 7 69 1 87 -8 93 -140 96 l-107 2 -3 26
-3 27 95 0 96 0 0 73 c0 89 -11 98 -114 100 -70 2 -71 3 -74 30 l-3 27 125 0
126 0 1 43 c0 23 2 52 3 64 1 12 -8 33 -20 47 -23 24 -25 24 -206 25 -101 0
-189 -3 -195 -5z"/>
					<path d="M1463 1603 c-10 -3 -13 -72 -13 -296 l0 -292 24 -22 c23 -22 31 -23
211 -23 178 0 187 1 192 20 6 23 43 27 43 5 0 -13 32 -15 230 -15 127 0 230 1
230 3 1 1 3 27 6 57 5 48 2 59 -18 83 -22 25 -29 27 -106 27 -45 0 -82 3 -82
8 0 4 47 95 104 202 84 159 101 197 90 206 -8 6 -14 16 -14 23 0 8 -64 11
-229 11 -259 0 -241 6 -241 -86 0 -41 4 -54 15 -54 8 0 15 -7 15 -15 0 -12 17
-15 90 -15 102 0 103 -2 66 -67 -14 -24 -57 -105 -97 -180 -91 -175 -99 -184
-99 -115 0 38 -4 52 -14 52 -8 0 -17 7 -20 16 -5 14 -16 16 -71 10 -35 -3 -82
-4 -104 0 -34 6 -41 10 -41 30 0 23 3 23 98 26 l97 3 0 66 c0 59 -3 69 -25 87
-17 14 -40 20 -75 20 -98 1 -95 0 -95 27 l0 24 123 3 122 3 3 63 c3 55 0 66
-20 87 -23 24 -25 25 -203 24 -99 0 -186 -3 -192 -6z"/>
					<path d="M2426 1602 c-3 -5 -6 -138 -6 -295 -2 -272 -1 -288 18 -312 l20 -25
194 2 193 3 3 63 c3 57 0 66 -22 89 -24 24 -30 25 -98 19 -89 -8 -132 2 -136
32 -3 20 1 21 95 24 l98 3 3 63 c3 56 0 67 -20 87 -21 21 -33 23 -92 22 -70
-2 -94 9 -83 38 5 11 32 15 129 17 l123 3 3 63 c3 55 0 66 -20 87 -23 24 -25
25 -210 25 -103 0 -189 -4 -192 -8z"/>
					<path d="M150 1584 c-73 -32 -110 -86 -110 -162 0 -86 75 -164 190 -200 70
-21 79 -31 55 -56 -25 -25 -63 -20 -93 13 l-27 30 -63 -14 c-35 -7 -66 -15
-69 -18 -11 -11 19 -77 52 -114 48 -55 111 -84 183 -84 90 -1 147 29 191 101
60 99 -18 225 -168 270 -72 22 -87 40 -59 68 17 17 58 15 58 -3 0 -8 7 -15 15
-15 8 0 15 -7 15 -15 0 -8 3 -15 8 -15 20 1 134 29 139 34 3 3 -2 26 -11 50
-19 49 -80 110 -132 132 -43 18 -130 17 -174 -2z"/>
					<path d="M487 1593 c-4 -3 -7 -33 -7 -65 0 -78 19 -98 95 -98 l55 0 0 -202 c0
-201 1 -203 24 -225 20 -19 35 -23 95 -23 l71 0 2 223 3 222 73 3 72 3 0 65
c0 48 -4 66 -15 70 -8 4 -15 12 -15 20 0 12 -39 14 -223 14 -123 0 -227 -3
-230 -7z"/>
					<path d="M3070 1535 c0 -85 15 -103 90 -107 l55 -3 3 -203 c3 -247 2 -245 115
-240 l72 3 1 220 1 220 57 3 58 3 -9 -33 c-15 -61 -9 -180 12 -222 24 -51 113
-140 166 -166 83 -43 213 -44 293 -3 54 28 131 115 149 167 39 113 14 216 -75
311 -77 83 -131 109 -235 113 -75 4 -89 1 -145 -25 -35 -17 -74 -42 -88 -56
-33 -35 -40 -34 -40 8 0 22 -8 43 -20 55 -19 19 -33 20 -240 20 l-220 0 0 -65z
m843 -123 c44 -29 56 -53 57 -107 0 -90 -68 -155 -162 -155 -145 0 -183 170
-56 253 51 33 118 37 161 9z"/>
					<path d="M4177 1593 c-4 -3 -7 -133 -7 -288 0 -281 0 -282 23 -303 19 -18 35
-22 85 -22 42 0 62 4 63 13 0 6 1 42 1 79 0 77 -2 75 92 77 222 5 305 234 139
381 -63 55 -120 70 -268 70 -67 0 -125 -3 -128 -7z m284 -173 c27 -15 25 -64
-3 -84 -12 -9 -42 -16 -65 -16 l-43 0 0 48 c0 27 3 52 7 55 10 11 82 8 104 -3z"/>
					<path d="M4907 1593 c-4 -3 -7 -90 -7 -193 0 -220 8 -254 78 -329 64 -68 117
-91 215 -91 88 0 127 13 182 61 71 62 80 97 83 318 2 193 2 194 -22 218 -21
21 -32 24 -88 21 l-63 -3 -5 -190 c-3 -104 -9 -197 -15 -206 -30 -47 -118 -65
-163 -33 -21 15 -22 20 -22 201 l0 186 -26 23 c-20 20 -36 24 -83 24 -32 0
-61 -3 -64 -7z"/>
					<path d="M5500 1311 c0 -346 -4 -334 101 -329 l64 3 5 80 5 80 87 6 c102 7
150 32 195 101 23 34 28 52 28 102 -1 72 -19 110 -78 168 -65 63 -92 71 -259
76 l-148 4 0 -291z m300 104 c18 -11 11 -51 -14 -74 -18 -17 -33 -22 -67 -19
-40 3 -44 6 -47 32 -2 15 -1 40 3 54 6 23 9 24 60 19 30 -3 59 -8 65 -12z"/>
				</g>
			</svg>
		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
